
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.title {
  margin-top: 30px;
  @include breakpoint('large') {
    margin-top: 60px;
  }
  h2 {
    font-size: 25px;
    font-weight: typography.$bold;
    text-align: center;
    @include breakpoint('medium') {
      font-size: 27px;
    }
    @include breakpoint('large') {
      text-align: left;
    }
  }
}
