
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
button.apple-pay-button {
  width: 100%;
  height: 41px;
  background-color: black;
  &:hover,
  &:active,
  &:focus {
    background-color: #3c4043 !important;
  }
}
.btn-apple {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  padding: 1px !important;
  border: 1px solid color.$black;
  background: color.$black;
}
.checkoutGuest .btn-apple {
  height: 41px;
}
.addedtocart .btn-apple {
  height: 41px;
}
.addedtocart .apple-btn-container,
.checkoutGuest .apple-btn-container,
.addedtocart .orderSummaryCheckout .googlePay,
.checkoutGuest .googlePay {
  padding-right: 0px !important;
  padding-left: 7px;
  top: 2px;
}
.f-style {
  .addedtocart .apple-btn-container,
  .checkoutGuest .apple-btn-container,
  .shopping-cart-page .order-cart-summary .apple-btn-container,
  .shopping-cart-page .order-cart-summary .googlePay,
  .addedtocart .orderSummaryCheckout .googlePay,
  .checkoutGuest .googlePay {
    top: 0;
  }
}
.shopping-cart-page .order-cart-summary .apple-btn-container,
.shopping-cart-page .order-cart-summary .googlePay {
  top: 2px;
}

#cartAffix .btn-apple {
  height: 41px;
}

@media screen and (max-width: 767px) {
  .checkoutGuest .btn-apple {
    height: 41px;
  }
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    height: 41px;
    -webkit-appearance: -apple-pay-button;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 100px;
    min-height: 32px;
    max-height: 64px;
  }
}
