
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.error-msg {
  color: color.$red;
  font-size: 14px;
  margin: 0 0 7px;
}
