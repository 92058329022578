
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.modal-dialog {
  max-width: 600px;
  padding: 10px;
}

.authorization-input,
.gift-card-input {
  width: 100%;
  input {
    height: 34px;
    font-weight: typography.$light;
    font-size: 16px;
    @include breakpoint('medium') {
      font-size: 0.875rem;
    }
    color: color.$grey10;
    &:focus {
      color: color.$grey10;
    }
  }
  &.error-msg {
    input {
      border-color: color.$red;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px #ce8483;
    }
  }
}

.error-message,
.top-error-message {
  margin-bottom: 10px;
  color: color.$red-neon;
  font-weight: typography.$light;
  display: block;
}

.error-message {
  margin-top: -10px;
}

.check-balance-modal {
  .modal-dialog {
    max-width: 620px;
    h4 {
      font-size: 22px;
      font-weight: typography.$bold;
      line-height: 29px;
      margin-bottom: 0;
    }
  }
}

@include breakpoint('small') {
  .check-balance-modal {
    .modal-dialog {
      margin: 70px auto 0;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.need-help {
  color: color.$primary-color;
  font-weight: typography.$light;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.product-need-help-wrap {
  padding: 0 15px;
  img {
    margin: 0 0 19px;
    width: 100%;
    height: auto;
    @include breakpoint('medium') {
      width: 65%;
    }
  }
}

.balance-wrap {
  margin: 30px 0 15px;
  h3 {
    color: color.$black-color;
    font-size: 22px;
    line-height: 29px;
    font-weight: typography.$bold;
    span.green {
      color: color.$light-green;
    }
  }
}

.gift-card-title {
  font-size: 22px;
  color: color.$black-color;
  margin-bottom: 16px;
  margin-top: 16px;
}
