
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.heading1 {
  font-size: 36px;
}

.heading2 {
  font-size: 34px;
  font-weight: typography.$bold;
  letter-spacing: -0.015em;
  color: color.$gray2;
  @media screen and (max-width: 767px) {
    font-size: 22px;
    line-height: 29px;
  }
}

.heading3 {
  color: color.$gray;
  font-size: 30px;
}

.heading4 {
  color: color.$gray;
  font-size: 24px;
}

.heading5 {
  color: color.$gray;
  font-size: 18px;
}

.heading5 {
  color: color.$black-color;
  font-weight: typography.$bold;
  letter-spacing: -0.015em;
  font-size: 14px;
  line-height: 18px;
}
